import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/header-one";
import Footer from "@layout/footer/footer-one";
import PageHeader from "@components/pageheader";
import ContactFormArea from '@containers/contact-us/contact-form-area'


const StudyRequestInteriorDesign = ({ pageContext, location }) => (
	<Layout location={location}>
		<Seo title="Contact Us" />
		<Header />
		<PageHeader
			pageContext={pageContext}
			location={location}
			title="Contact Us"
			pageTitle="study_request"
		/>
		<main className="site-wrapper-reveal">
			<ContactFormArea studyRequestType="Reahbitition" formType="study-request"  isRoute={true}/>
			
		
		</main>
		<Footer />
	</Layout>
)

export default StudyRequestInteriorDesign